import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia,
} from "@fishrmn/fishrmn-components";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
              businessName
              logo
            }
          }
        }
        allFishermanImages {
          edges {
            node {
              heroImages {
                url
              }
              galleryImages {
                url
              }
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const businessData = queryData.allFisherman.edges[0].node;
      const imageData = queryData.allFishermanImages.edges[0].node;
      const data = { ...businessData, ...imageData };

      return (
        <div className="site-container">
          <div>
            <Navigation
              links={[
                { pageTitle: "Home", slug: "/", internal: true },
                { pageTitle: "Contact", slug: "/contact/", internal: true },
                { pageTitle: "Catering", slug: "/catering/", internal: true },
                {
                  pageTitle: "Order Online",
                  slug: "https://mybishos.square.site/s/order",
                  internal: false,
                },
              ]}
              backgroundColor={"white"}
              bordered={false}
              centerMobileNavItems={true}
              evenSpacing={false}
              fixed={false}
              header={data.businessName}
              linksAs={"text"}
              logo={data.logo}
              primaryContentPosition={"left"}
              primaryContentCentered={false}
              internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
            backgroundColor={"primary"}
            horizontalAlign={"center"}
            verticalAlign={"middle"}
            columns={[
              <CopyrightLabel
                company={data.businessName}
                phrase={"All Rights Reserved"}
              />,
              <SiteMap
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },
                  { pageTitle: "Contact", slug: "/contact/", internal: true },
                  { pageTitle: "Catering", slug: "/catering/", internal: true },
                  {
                    pageTitle: "Order Online",
                    slug: "https://mybishos.square.site/s/order",
                    internal: false,
                  },
                ]}
                horizontal={true}
                bulleted={false}
                internalLinkComponent={Link}
              />,
            ]}
          />
        </div>
      );
    }}
  />
);
